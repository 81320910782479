'use client';
import JobAutoWhitepaperModal from '@finn/auto-ui/components/JobAuto/JobAutoWhitepaperModal';
import PageBodyBuilder from '@finn/auto-ui/ui-modules/PageBodyBuilder';
import { ModalContainer, ModalKey } from '@finn/ua-modals';

export const B2BJobAuto = async ({ pageData }) => {
  return (
    <PageBodyBuilder data={pageData} stickyHeader>
      <ModalContainer
        modalKey={ModalKey.JOBAUTO_WHITEPAPER_MODAL}
        ModalComponent={JobAutoWhitepaperModal}
      />
    </PageBodyBuilder>
  );
};
